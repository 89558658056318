const feather = require('feather-icons');

export function modal() {
    var $modalWrapper = $('#modal-wrapper');
    var $modalBody =  $('#modal-body');
    var $mloader = $("<div id='modal-loader' class='loader'>");
    var $overlay = $('#modal-overlay');
    var $body = $('body');
    var modalOpen = false;

    $(document).on('ajax:send', '.js-modal', function(e, data, status, xhr){
        if (!modalOpen) {
            showOverlay();
        }
    }).on('ajax:success', function(e, data, status, xhr){
          $mloader.remove();
          $modalBody.show().css({'user-select':'text'});
          modalOpen = true;
          feather.replace();
    });


    // close modal
    $(document).on('ajax:success', '.close-on-submit', function(e) {
      closeModal();
    });

    $overlay.on('click', function(e) {
        closeModal();
    });
    $modalWrapper.on('click', function(e) {
      if ($(e.target).attr('id') == $modalWrapper.attr('id')) {
        closeModal();
      }
    });

    $(document).on('click', '.close-modal', function(e) {
        closeModal();
    });

    function showOverlay() {
      $overlay.css({display:'block', opacity:0}).fadeTo(200, 1);
      $body[0].scrollHeight > $(window).height() ? $body.addClass('modal-open'): '';
      $modalWrapper.show().promise().then(function() {
        $modalWrapper.addClass('visible').append($mloader);
      });
    };

    function closeModal() {
      $overlay.fadeOut();
      $modalBody.fadeOut().empty().removeClass();
      $body.removeClass('modal-open');
      $modalWrapper.removeClass('visible').on('transitionend',
        function(e) {
          $(this).hide().off(e);
        });
      modalOpen = false;
    }

}
