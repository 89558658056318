const feather = require('feather-icons');
const Bouncer = require('formbouncerjs');
import Choices from 'choices.js'
import flatpickr from "flatpickr";
require("flatpickr/dist/themes/light.css");



import { modal } from "./modal.js"
import { initTabs, initDropdowns } from "./utils.js"


$(document).on('turbolinks:load', function() {
    feather.replace();

    modal();
    initTabs();
    initDropdowns();

    var validate = new Bouncer('.bouncerForm', {
        messageTarget: 'data-bouncer-target',
        messages: {
          missingValue: {
            default: function(field) { return field.getAttribute('data-bouncer-message') ? field.getAttribute('data-bouncer-message') : 'This field is required' }
          }
        }
    });

    $('#js-signup').on('click', function(e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({scrollTop: $(aid).offset().top},'slow');
    });

    $('#js-toggle-filters').on('click', function(e) {
        e.preventDefault();
        var $filters = $('#filters');
        $filters.toggle();
    });


    $('.js-mobile-toggle').on('click', function(e) {
        if ($('.nav').hasClass('visible-mobile')) {
            $('.nav').removeClass('visible-mobile');
            $(this).removeClass('is-active');
        }
        else {
            $('.nav').addClass('visible-mobile');
            $(this).addClass('is-active');
        }
    });


    // search filters
    $('.js-choice-multiple').each(function(i, el) {
        var choices = new Choices(el, {
              removeItemButton: true
          });
      });

    $('.js-choice-single').each(function(i, el) {
        var choices = new Choices(el);
    })

    if ($('.js-flatpickr-start').length) {
        let pickrFrom = $('.js-flatpickr-start').flatpickr();
        pickrFrom.setDate($('.js-flatpickr-start').data('start'));
    }

    if ($('.js-flatpickr-end').length) {
        let pickrTo = $('.js-flatpickr-end').flatpickr();
        pickrTo.setDate($('.js-flatpickr-end').data('end'));
    }



});


