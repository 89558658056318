export function initTabs() {
    $('.js-content-tabs .tab').on('click', function(e){
       e.preventDefault();
       var $this = $(this);
       $('.tab').removeClass('active');
       $this.addClass('active');
       $('.tab-content').hide();
       $($this.data('tab')).show();
     })
}


export function initDropdowns() {
    $('.dropdown-toggle').on("click", function(e) {
      e.preventDefault();
      var $this = $(this);
      var $menu = $this.next('.dropdown-menu');
      $this.toggleClass('expanded');
      $menu.toggleClass('open');
    });

    $(document).on('click touchend', function(e) {
      var $dds = $('.action-dropdown');
      var $targ = $(e.target);
      $dds.not($dds.has($targ)).children('.dropdown-menu').removeClass('open');
      $dds.not($dds.has($targ)).children('.dropdown-toggle').removeClass('expanded');
    });
}
